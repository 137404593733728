import { useEffect } from "react";
import Stats from "stats.js";

export const usePerformanceStats = (): void => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).__HEX_PERFORMANCE_MONITOR__ = HexPerformanceMonitor();
  }, []);
};

const STATS_MONITOR_CLASS = "stats-monitor";
const HexPerformanceMonitor = (): { toggleMonitoring: () => void } => {
  let requestId: number | undefined;
  return {
    toggleMonitoring: () => {
      if (requestId == null) {
        const allStats = [0, 1, 2].map((p) => {
          const stats = new Stats();
          stats.showPanel(p); // 0: fps, 1: ms, 2: mb, 3+: custom
          stats.dom.classList.add(STATS_MONITOR_CLASS);
          stats.dom.style.cssText = `position:absolute;bottom:0px;right:${
            p * 80
          }px;z-index:999999`;
          document.body.appendChild(stats.dom);
          return stats;
        });

        const animate = (): void => {
          allStats.forEach((s) => {
            s.update();
          });

          requestId = requestAnimationFrame(animate);
        };

        requestId = requestAnimationFrame(animate);
      } else {
        requestId != null && cancelAnimationFrame(requestId);
        const elements = document.getElementsByClassName(STATS_MONITOR_CLASS);
        Array.from(elements).forEach((e) => e.parentElement?.removeChild(e));
        requestId = undefined;
      }
    },
  };
};
