import { css } from "styled-components";
import normalize from "styled-normalize";

/**
 * Basic reset stylesheets to sit ontop of 'normalize.css'
 *
 * Draws from CSS Remedy and Tailwind's Preflight
 *
 * - https://necolas.github.io/normalize.css/
 * - https://github.com/mozdevs/cssremedy
 * - https://tailwindcss.com/docs/preflight
 */
export const reset = css`
  ${normalize}

  /**
   * Prevent padding and border from affecting element width
   *
   * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   */
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  /**
   * Immediately jump any animation to the end point if the user has set their device to "prefers reduced motion"
   * or if printing.
   * This could create bad, unintended consequences. Remove if needed.
   *
   * https://github.com/mozdevs/cssremedy/blob/85f5e2555ee38011afb2f763abcdf0ea2c15c6ac/remedy.css#L9-L17
   */
  /* stylelint-disable-next-line media-feature-name-no-unknown */
  @media (prefers-reduced-motion: reduce), (print) {
    * {
      transition-duration: 0.001s !important;
      animation-duration: 0.001s !important;
      animation-iteration-count: 1 !important;
    }
  }

  /**
   * Overflow by default is bad.
   *
   * https://github.com/mozdevs/cssremedy/blob/85f5e2555ee38011afb2f763abcdf0ea2c15c6ac/remedy.css#L54-L56
   */
  pre {
    white-space: pre-wrap;
  }

  /**
   * Ensure 'hr' is visible
   *
   * https://github.com/mozdevs/cssremedy/blob/85f5e2555ee38011afb2f763abcdf0ea2c15c6ac/remedy.css#L58-L60
   */
  hr {
    border: 0.5px solid;
  }

  /**
   * Switch display mode to block since thats what we usually want for images.
   * If you override, and make an image inline, it's likely you'll want middle vertical alignment.
   *
   * https://github.com/mozdevs/cssremedy/blob/85f5e2555ee38011afb2f763abcdf0ea2c15c6ac/remedy.css#L69-L72
   */
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;

    vertical-align: middle; /* Ignore the warning here */
  }

  /**
   * Constrain images and videos to the parent width and preserve
   * their intrinsic aspect ratio.
   *
   * https://github.com/mozdevs/cssremedy/blob/85f5e2555ee38011afb2f763abcdf0ea2c15c6ac/remedy.css#L73-L76
   */
  img,
  video {
    max-width: 100%;
    height: auto;
  }

  /**
   * Don't let users resize textareas horizontally by default
   *
   * https://github.com/tailwindcss/tailwindcss/blob/84964c7744db9531c2509b03e06ddc7f5fad39b0/src/plugins/css/preflight.css#L129-L136
   */
  textarea {
    resize: vertical;
  }

  /**
   * Make sure we always get a pointer cursor on buttons
   */
  button,
  [role="button"] {
    cursor: pointer;
  }

  /**
   * Avoid double lines on tables
   *
   * https://github.com/tailwindcss/tailwindcss/blob/84964c7744db9531c2509b03e06ddc7f5fad39b0/src/plugins/css/preflight.css#L143-L145
   */
  table {
    border-collapse: collapse;
  }

  /**
   * Adjust placeholder opacity on Firefox.
   *
   * https://github.com/necolas/normalize.css/issues/741
   */
  ::-moz-placeholder {
    opacity: 1;
  }
`;
