import { Classes } from "@blueprintjs/core";
import { DEFAULT_FONT_FAMILY, assertNever } from "@hex/common";
import type { editor as Editor } from "monaco-editor";
import { rgba } from "polished";
import { css } from "styled-components";

import {
  CodeColorsType,
  MarkdownFontSize,
  MarkdownSize,
  ThemeInterpolation,
} from "./theme";

const resets = {
  a: css`
    color: ${({ theme }) => theme.fontColor.LINK};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.fontColor.LINK};
      text-decoration: none;
    }
    &:active {
      color: ${({ theme }) => theme.fontColor.LINK};
      text-decoration: none;
    }
    &:visited {
      color: ${({ theme }) => theme.fontColor.LINK};
      text-decoration: none;
    }
  `,
};

const borderRadius = "3px";

const cellMargin = 50;

const fontSize = {
  EXTRA_SMALL: "10px",
  SMALL: "12px",
  DEFAULT: "14px",
  LARGE: "16px",
  EXTRA_LARGE: "20px",
  HEADING_FIVE: "10px",
  HEADING_FOUR: "16px",
  HEADING_THREE: "20px",
  HEADING_TWO: "24px",
  HEADING_ONE: "28px",
  PROJECT_TITLE: "42px",
  DISPLAY_HEADING_ONE: "42px",
};

const lineHeight = {
  EXTRA_SMALL: "12px",
  SMALL: "16px",
  DEFAULT: "20px",
  LARGE: "22px",
  EXTRA_LARGE: "27px",
  MARKDOWN_HEADING: "1.2",
};

const letterSpacing = {
  DISPLAY: "-0.05em",
  HEADING: "-0.025em",
  MARKDOWN_HEADING: "-0.04em",
};

const fileDropZone = {
  backgroundColor: css`
    ${({ theme }) => rgba(theme.intent.SUCCESS, 0.1)}
  `,
  borderColor: css`
    ${({ theme }) => rgba(theme.intent.SUCCESS, 0.4)}
  `,
};

const disabledState = css`
  cursor: not-allowed;
  opacity: 0.35;
`;

export const markdownLargeFontSize: MarkdownFontSize = {
  PARAGRAPH: "16px",
  HEADER_FOUR: "20px",
  HEADER_THREE: "26px",
  HEADER_TWO: "32px",
  HEADER_ONE: "38px",
};

export const markdownDefaultFontSize: MarkdownFontSize = {
  PARAGRAPH: "14px",
  HEADER_FOUR: "18px",
  HEADER_THREE: "24px",
  HEADER_TWO: "30px",
  HEADER_ONE: "36px",
};

export const markdownSmallFontSize: MarkdownFontSize = {
  PARAGRAPH: "12px",
  HEADER_FOUR: "13px",
  HEADER_THREE: "14px",
  HEADER_TWO: "15px",
  HEADER_ONE: "16px",
};

// markdownStyles is used for markdown cells, text cells, and plate (comments, magic prompt, etc)
// This is an intentionally larger line height than static paragraph text has
// to be more ergonomic for inline editing and @ mentions of tables, people, etc.
const markdownLineHeight = {
  DEFAULT_PARAGRAPH: "22px",
  SMALL_PARAGRAPH: "19px",
  LARGE_PARAGRAPH: "24px",
};

const adminHeaderFontSize = {
  HEADER_ONE: "22px",
  HEADER_TWO: "18px",
};

const animation = {
  duration: "0.15s",
  durationMs: 150,
  durationMedium: "0.25s",
  durationMediumMs: 250,
  durationLarge: "0.6s",
  durationLargeMs: 600,
  easing: "cubic-bezier(0.25, 0.1, 0.25, 1)",
};

const fontWeight = {
  NORMAL: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
};

const fontFamily = {
  DEFAULT: DEFAULT_FONT_FAMILY,
  MONO: '"IBM Plex Mono", "Courier New", Courier, monospace',
};

const iconSize = {
  DEFAULT: 16,
  LARGE: 24,
};

const mediaQuery = {
  MOBILE: 450,
  EXTRA_SMALL: 700,
  SMALL: 945,
  MEDIUM: 1050,
  LARGE: 1400,
  EXTRA_LARGE: 2100,
};

// Non-standard media query breakpoints
const storyMaxWidth = 860;
const subNavigationBreakpoint = 1124;
const homeMaxWidth = 1350;

const marketingColors = {
  AMETHYST: "#A477B2",
  BACKGROUND: "#14141C",
  CEMENT: "#717A94",
  CITRINE: "#CDA849",
  JADE: "#5CB198",
  OPAL: "#FBF9F9",
  ROSE_QUARTZ: "#F5C0C0",
  VIOLET_TOPAZ: "#5F509D",
};

export type HexThemeColors = {
  BLACK: string;
  OFF_WHITE: string;
  WHITE: string;
} & {
  [C in `${
    | "DARK_GRAY"
    | "GRAY"
    | "LIGHT_GRAY"
    | "BLUE"
    | "GREEN"
    | "ORANGE"
    | "RED"
    | "VERMILION"
    | "ROSE"
    | "VIOLET"
    | "INDIGO"
    | "COBALT"
    | "TURQUOISE"
    | "FOREST"
    | "LIME"
    | "GOLD"
    | "SEPIA"}${1 | 2 | 3 | 4 | 5}`]: string;
};

const colors: HexThemeColors = {
  BLACK: "#0b0e16",
  OFF_WHITE: "#f8fafb",
  WHITE: "#ffffff",
  DARK_GRAY1: "#01011b",
  DARK_GRAY2: "#11122b",
  DARK_GRAY3: "#191b34",
  DARK_GRAY4: "#2a2d44",
  DARK_GRAY5: "#3f4257",
  GRAY1: "#717a94",
  GRAY2: "#868ea4",
  GRAY3: "#9ca3b4",
  GRAY4: "#b1b6c4",
  GRAY5: "#c6cad4",
  LIGHT_GRAY1: "#cfd3de",
  LIGHT_GRAY2: "#dadde6",
  LIGHT_GRAY3: "#e4e6ec",
  LIGHT_GRAY4: "#ecedf2",
  LIGHT_GRAY5: "#eff1f4",
  BLUE1: "#143066",
  BLUE2: "#254685",
  BLUE3: "#365ca3",
  BLUE4: "#4871c2",
  BLUE5: "#5987e0",
  GREEN1: "#0f6b5b",
  GREEN2: "#1c866b",
  GREEN3: "#29a07c",
  GREEN4: "#36ba8c",
  GREEN5: "#43d59d",
  ORANGE1: "#ba4d10",
  ORANGE2: "#c85f22",
  ORANGE3: "#d67133",
  ORANGE4: "#e38344",
  ORANGE5: "#f19556",
  RED1: "#a41d35",
  RED2: "#b63245",
  RED3: "#c84654",
  RED4: "#da5a64",
  RED5: "#ed6f73",
  VERMILION1: "#9e2b0e",
  VERMILION2: "#b83211",
  VERMILION3: "#d13913",
  VERMILION4: "#eb532d",
  VERMILION5: "#ff6e4a",
  ROSE1: "#a82255",
  ROSE2: "#c22762",
  ROSE3: "#db2c6f",
  ROSE4: "#f5498b",
  ROSE5: "#ff66a1",
  VIOLET1: "#180460",
  VIOLET2: "#3e277a",
  VIOLET3: "#654a94",
  VIOLET4: "#8b6caf",
  VIOLET5: "#b28fc9",
  INDIGO1: "#5642a6",
  INDIGO2: "#634dbf",
  INDIGO3: "#7157d9",
  INDIGO4: "#9179f2",
  INDIGO5: "#ad99ff",
  COBALT1: "#1f4b99",
  COBALT2: "#2458b3",
  COBALT3: "#2965cc",
  COBALT4: "#4580e6",
  COBALT5: "#669eff",
  TURQUOISE1: "#008075",
  TURQUOISE2: "#00998c",
  TURQUOISE3: "#00b3a4",
  TURQUOISE4: "#14ccbd",
  TURQUOISE5: "#2ee6d6",
  FOREST1: "#1d7324",
  FOREST2: "#238c2c",
  FOREST3: "#29a634",
  FOREST4: "#43bf4d",
  FOREST5: "#62d96b",
  LIME1: "#728c23",
  LIME2: "#87a629",
  LIME3: "#9bbf30",
  LIME4: "#b6d94c",
  LIME5: "#d1f26d",
  GOLD1: "#a67908",
  GOLD2: "#bf8c0a",
  GOLD3: "#d99e0b",
  GOLD4: "#f2b824",
  GOLD5: "#ffc940",
  SEPIA1: "#63411e",
  SEPIA2: "#7d5125",
  SEPIA3: "#96622d",
  SEPIA4: "#b07b46",
  SEPIA5: "#c99765",
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const markdownStyles = (size: MarkdownSize) => {
  return css`
    padding: 0;

    color: ${({ theme }) => theme.fontColor.DEFAULT};
    font-size: ${() => {
      switch (size) {
        case "small":
          return markdownSmallFontSize.PARAGRAPH;
        case "default":
          return markdownDefaultFontSize.PARAGRAPH;
        case "large":
          return markdownLargeFontSize.PARAGRAPH;
        default:
          assertNever(size, size);
      }
    }};
    line-height: ${() => {
      switch (size) {
        case "small":
          return markdownLineHeight.SMALL_PARAGRAPH;
        case "default":
          return markdownLineHeight.DEFAULT_PARAGRAPH;
        case "large":
          return markdownLineHeight.LARGE_PARAGRAPH;
        default:
          assertNever(size, size);
      }
    }};

    *:first-child {
      margin-top: 0 !important;
    }

    *:last-child {
      margin-bottom: 0 !important;
    }

    a {
      background-color: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.fontColor.LINK};

      transition: background-color 0.3s ease;

      :hover,
      :focus {
        color: ${({ theme }) => theme.fontColor.LINK};
        text-decoration: none;

        background-color: ${({ theme }) => rgba(theme.fontColor.LINK, 0.12)};
      }
    }

    code {
      padding: 3px 4px;

      font-size: ${size === "small"
        ? fontSize.SMALL
        : size === "default"
          ? "14px"
          : fontSize.DEFAULT};
      font-family: ${({ theme }) => theme.fontFamily.MONO};
      line-height: ${size === "default" ? "26px" : "20px"};

      background-color: ${({ theme }) => theme.codeBackground};
      border-radius: ${({ theme }) => theme.borderRadius};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0.8em 0 5px;

      font-weight: ${fontWeight.SEMI_BOLD};

      line-height: ${lineHeight.MARKDOWN_HEADING};
      letter-spacing: ${letterSpacing.MARKDOWN_HEADING};

      code {
        font-weight: normal;
        font-size: inherit !important;
      }

      a.heading-link {
        margin-left: 5px;

        border-bottom: none;

        opacity: 0;

        transition: opacity 0.3s ease;

        span[class=${Classes.ICON}] {
          color: ${({ theme }) => theme.fontColor.MUTED};
          vertical-align: middle;
        }

        :hover,
        :focus {
          background-color: transparent;
          opacity: 1;

          span[class=${Classes.ICON}] {
            color: ${({ theme }) => theme.fontColor.LINK};
          }
        }
      }
    }

    h1 {
      font-size: ${() => {
        switch (size) {
          case "small":
            return markdownSmallFontSize.HEADER_ONE;
          case "default":
            return markdownDefaultFontSize.HEADER_ONE;
          case "large":
            return markdownLargeFontSize.HEADER_ONE;
          default:
            assertNever(size, size);
        }
      }};
    }

    h2 {
      font-size: ${() => {
        switch (size) {
          case "small":
            return markdownSmallFontSize.HEADER_TWO;
          case "default":
            return markdownDefaultFontSize.HEADER_TWO;
          case "large":
            return markdownLargeFontSize.HEADER_TWO;
          default:
            assertNever(size, size);
        }
      }};
    }

    h3 {
      font-size: ${() => {
        switch (size) {
          case "small":
            return markdownSmallFontSize.HEADER_THREE;
          case "default":
            return markdownDefaultFontSize.HEADER_THREE;
          case "large":
            return markdownLargeFontSize.HEADER_THREE;
          default:
            assertNever(size, size);
        }
      }};
    }
    h4,
    h5,
    h6 {
      font-size: ${() => {
        switch (size) {
          case "small":
            return markdownSmallFontSize.HEADER_FOUR;
          case "default":
            return markdownDefaultFontSize.HEADER_FOUR;
          case "large":
            return markdownLargeFontSize.HEADER_FOUR;
          default:
            assertNever(size, size);
        }
      }};
    }

    p {
      margin: 1px 0;
      padding: ${size === "default" ? "3px 0" : "1px 0"};

      font-size: ${() => {
        switch (size) {
          case "small":
            return markdownSmallFontSize.PARAGRAPH;
          case "default":
            return markdownDefaultFontSize.PARAGRAPH;
          case "large":
            return markdownLargeFontSize.PARAGRAPH;
          default:
            assertNever(size, size);
        }
      }};
      line-height: ${() => {
        switch (size) {
          case "small":
            return markdownLineHeight.SMALL_PARAGRAPH;
          case "default":
            return markdownLineHeight.DEFAULT_PARAGRAPH;
          case "large":
            return markdownLineHeight.LARGE_PARAGRAPH;
          default:
            assertNever(size, size);
        }
      }};
    }

    img {
      max-width: 100%;
    }

    hr {
      display: block;
      margin: 0;

      padding: 7px 0;

      background: transparent;
      border: none;

      &::before {
        display: block;
        height: 1px;

        background-color: ${({ theme }) => theme.borderColor.MUTED};

        content: "";
      }
    }

    blockquote {
      margin: 4px 0;
      padding: 3px 0 3px 12px;

      border-left: 3px solid ${({ theme }) => theme.borderColor.DEFAULT};
    }

    ul {
      li {
        list-style-type: disc;
      }
      ul {
        li {
          list-style-type: circle;
        }
        ul {
          li {
            list-style-type: square;
          }
        }
      }
    }

    /* stylelint-disable no-descending-specificity */
    ol,
    ul {
      margin: 1px 0;
      /* this needs to be 35px so that numbered list items don't get cut off */
      padding-inline-start: 35px;

      padding-left: 35px;

      font-size: {
        font-size: ${() => {
          switch (size) {
            case "small":
              return markdownSmallFontSize.PARAGRAPH;
            case "default":
              return markdownDefaultFontSize.PARAGRAPH;
            case "large":
              return markdownLargeFontSize.PARAGRAPH;
            default:
              assertNever(size, size);
          }
        }};
      }
      line-height: 1.5;

      ol,
      ul {
        margin-top: 0;
        margin-bottom: 0;
      }

      li {
        padding: ${size === "default" ? "3px 0" : "1px 0"};

        p {
          margin: 0;
        }
      }

      li.task-list-item {
        list-style-type: none;

        input {
          margin-left: -18px;
        }
      }
    }
    /* stylelint-enable no-descending-specificity */

    pre {
      background-color: ${({ theme }) => theme.codeBackground};

      &[style] {
        margin-bottom: 24px !important;
        padding: ${size === "default"
          ? "15px 0 15px 20px"
          : "10px 0 10px 15px"} !important;

        &:last-child {
          margin-bottom: 0 !important;
        }

        border-radius: ${({ theme }) => theme.borderRadius};
      }

      > code {
        padding: 0;

        line-height: ${size === "small"
          ? "18px"
          : size === "default"
            ? "22px"
            : "20px"} !important;
      }
    }

    table {
      margin-bottom: 24px;

      font-size: ${size === "small" ? fontSize.SMALL : fontSize.DEFAULT};

      thead {
        border-bottom: 1px solid ${({ theme }) => theme.borderColor.DEFAULT};

        th {
          text-align: center;
        }

        tr th {
          padding: 0 10px 5px 0;

          font-weight: 500;
        }
      }

      tbody {
        tr td {
          padding: 4px 10px 4px 0;

          color: ${({ theme }) => theme.fontColor.DEFAULT};
        }
      }
    }
  `;
};

export const pillCss = (pillColors: {
  backgroundColor: string;
  color: string;
}): ThemeInterpolation => {
  return css`
    display: inline-block;
    height: 18px;
    padding: 3px 4px;

    color: ${pillColors.color};
    font-family: ${({ theme }) => theme.fontFamily.MONO};
    line-height: 14px;

    background-color: ${pillColors.backgroundColor};

    border-radius: ${({ theme }) => theme.borderRadius};
  `;
};

export const monacoPillCss = (pillColors: {
  backgroundColor: string;
  color: string;
}): ThemeInterpolation => {
  return css`
    display: inline-block;
    height: 18px;
    margin: 0 -2px;
    padding: 1px 2px;

    color: ${pillColors.color};
    font-family: ${({ theme }) => theme.fontFamily.MONO};
    line-height: 14px;

    border-radius: ${({ theme }) => theme.borderRadius};

    :hover {
      background-color: ${pillColors.backgroundColor};
    }
  `;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const generateMarkdownCodeTheme = (
  isInAppView: boolean,
  allowScroll: boolean,
  codeColors: CodeColorsType,
) => {
  return {
    'code[class*="language-"]': {
      textAlign: "left",
      whiteSpace: "pre",
      wordSpacing: "normal",
      wordBreak: "normal",
      wordWrap: "normal",
      fontFamily: `${fontFamily.MONO}`,
      color: codeColors.general.DEFAULT,
      background: !isInAppView ? "transparent" : codeColors.appView.BACKGROUND,
      fontSize: !isInAppView ? fontSize.SMALL : fontSize.DEFAULT,
      lineHeight: "1.5em",
      MozTabSize: "4",
      OTabSize: "4",
      tabSize: "4",
      WebkitHyphens: "none",
      MozHyphens: "none",
      msHyphens: "none",
      hyphens: "none",
    },
    'pre[class*="language-"]': {
      textAlign: "left",
      whiteSpace: "pre",
      wordSpacing: "normal",
      wordBreak: "normal",
      wordWrap: "normal",
      fontFamily: `${fontFamily.MONO}`,
      color: codeColors.general.DEFAULT,
      background: !isInAppView ? "transparent" : codeColors.appView.BACKGROUND,
      fontSize: !isInAppView ? fontSize.SMALL : fontSize.DEFAULT,
      lineHeight: "1.5em",
      MozTabSize: "4",
      OTabSize: "4",
      tabSize: "4",
      WebkitHyphens: "none",
      MozHyphens: "none",
      msHyphens: "none",
      hyphens: "none",
      overflow: allowScroll ? "auto" : "hidden",
      position: "relative",
      margin: "0",
      padding: "1em 1.25em",
    },
    ':not(pre) > code[class*="language-"]': {
      whiteSpace: "normal",
      borderRadius: "0.2em",
      padding: "0.1em",
    },
    '[class*="language-"] .namespace': {
      Opacity: "0.7",
    },
    'code[class*="language-"]::-moz-selection': {
      background: codeColors.general.SELECTION,
    },
    'pre[class*="language-"]::-moz-selection': {
      background: codeColors.general.SELECTION,
    },
    'code[class*="language-"] ::-moz-selection': {
      background: codeColors.general.SELECTION,
    },
    'pre[class*="language-"] ::-moz-selection': {
      background: codeColors.general.SELECTION,
    },
    'code[class*="language-"]::selection': {
      background: codeColors.general.SELECTION,
    },
    'pre[class*="language-"]::selection': {
      background: codeColors.general.SELECTION,
    },
    'code[class*="language-"] ::selection': {
      background: codeColors.general.SELECTION,
    },
    'pre[class*="language-"] ::selection': {
      background: codeColors.general.SELECTION,
    },
    ".language-css > code": {
      color: codeColors.css.DEFAULT,
    },
    ".language-sass > code": {
      color: codeColors.css.DEFAULT,
    },
    ".language-scss > code": {
      color: codeColors.css.DEFAULT,
    },
    atrule: {
      color: codeColors.general.KEYWORD,
    },
    "attr-name": {
      color: codeColors.general.KEYWORD,
    },
    "attr-value": {
      color: codeColors.general.KEYWORD,
    },
    attribute: {
      color: codeColors.general.KEYWORD,
    },
    boolean: {
      color: codeColors.general.BOOLEAN,
    },
    builtin: {
      color: codeColors.general.KEYWORD,
    },
    cdata: {
      color: codeColors.general.KEYWORD,
    },
    char: {
      color: codeColors.general.KEYWORD,
    },
    class: {
      color: codeColors.general.KEYWORD,
    },
    "class-name": {
      color: codeColors.general.KEYWORD,
    },
    color: {
      color: codeColors.general.KEYWORD,
    },
    comment: {
      color: codeColors.general.COMMENT,
    },
    constant: {
      color: codeColors.general.DEFAULT,
    },
    deleted: {
      color: codeColors.general.INVALID,
    },
    doctype: {
      color: codeColors.general.COMMENT,
    },
    entity: {
      color: codeColors.general.DEFAULT,
    },
    function: {
      color: codeColors.general.DEFAULT,
    },
    hexcode: {
      color: codeColors.general.STRING,
    },
    id: {
      color: codeColors.general.KEYWORD,
      fontWeight: "bold",
    },
    important: {
      color: codeColors.general.INVALID,
      fontWeight: "bold",
    },
    inserted: {
      color: codeColors.general.KEYWORD,
    },
    keyword: {
      color: codeColors.general.KEYWORD,
    },
    number: {
      color: codeColors.general.NUMBER,
    },
    operator: {
      color: codeColors.general.DEFAULT,
    },
    prolog: {
      color: codeColors.general.COMMENT,
    },
    property: {
      color: codeColors.general.KEYWORD,
    },
    "pseudo-class": {
      color: codeColors.general.KEYWORD,
    },
    "pseudo-element": {
      color: codeColors.general.KEYWORD,
    },
    punctuation: {
      color: codeColors.general.DEFAULT,
    },
    regex: {
      color: codeColors.general.DEFAULT,
    },
    selector: {
      color: codeColors.general.KEYWORD,
    },
    string: {
      color: codeColors.general.STRING,
    },
    symbol: {
      color: codeColors.general.KEYWORD,
    },
    tag: {
      color: codeColors.general.KEYWORD,
    },
    unit: {
      color: codeColors.general.KEYWORD,
    },
    url: {
      color: codeColors.html.STRING,
    },
    variable: {
      color: codeColors.general.KEYWORD,
    },
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const generateMonacoRules = (
  codeColors: CodeColorsType,
): Editor.ITokenThemeRule[] => {
  return [
    { token: "", foreground: codeColors.general.DEFAULT },
    { token: "invalid", foreground: codeColors.general.INVALID },

    // General
    {
      token: "identifier",
      foreground: codeColors.general.IDENTIFIER,
    },
    {
      token: "delimiter",
      foreground: codeColors.general.DELIMITER,
    },
    {
      token: "comment",
      foreground: codeColors.general.COMMENT,
    },
    {
      token: "string",
      foreground: codeColors.general.STRING,
    },
    {
      token: "variable",
      foreground: codeColors.general.KEYWORD,
    },
    {
      token: "keyword",
      foreground: codeColors.general.KEYWORD,
    },
    {
      token: "number",
      foreground: codeColors.general.NUMBER,
    },
    {
      token: "number.hex",
      foreground: codeColors.general.NUMBER,
    },
    {
      token: "tag",
      foreground: codeColors.general.TAG,
    },

    // HTML specific
    {
      token: "attribute.name.html",
      foreground: codeColors.html.ATTRIBUTE,
    },
    {
      token: "string.html",
      foreground: codeColors.html.STRING,
    },

    // SQL specific
    {
      token: "string.sql",
      foreground: codeColors.general.STRING,
    },
    {
      token: "predefined.sql",
      foreground: codeColors.sql.PREDEFINED,
    },

    // YAML specific
    {
      token: "string.yaml",
      foreground: codeColors.general.DEFAULT,
    },

    // JSON specific
    {
      token: "string.key.json",
      foreground: codeColors.general.KEYWORD,
    },
    {
      token: "string.value.json",
      foreground: codeColors.general.STRING,
    },

    // Calc specific
    {
      token: "identifier.column.calc",
      foreground: codeColors.calc.COLUMN,
    },

    {
      token: "param-reference.calc",
      foreground: codeColors.calc.PARAMETER_REFERENCE,
    },

    {
      token: "param-reference.identifier.calc",
      foreground: codeColors.calc.PARAMETER_REFERENCE,
    },
  ];
};

export default {
  markdownLargeFontSize,
  markdownDefaultFontSize,
  markdownSmallFontSize,
  adminHeaderFontSize,
  animation,
  borderRadius,
  cellMargin,
  colors,
  disabledState,
  resets,
  fileDropZone,
  fontFamily,
  fontSize,
  fontWeight,
  letterSpacing,
  iconSize,
  markdownDefaultfontSize: markdownDefaultFontSize,
  markdownSmallfontSize: markdownSmallFontSize,
  marketingColors,
  mediaQuery,
  storyMaxWidth,
  homeMaxWidth,
  subNavigationBreakpoint,
  lineHeight,
};
