import { ConnectionStatus } from "./ConnectionStatus";
import { logInfoMsg } from "./logging";

interface ConnectionEntry {
  time: Date;
  status: ConnectionStatus;
}

function formatConnectionEntry(entry: ConnectionEntry): string {
  return `status: ${entry.status.padEnd(12)}, time: ${entry.time}`;
}

/**
 * Circular buffer of recent connection changes to surface in the UI
 */
class ConnectionLog {
  public numberDisconnects = 0;
  public numberOfDegradedConnections = 0;

  private readonly maxEntries: number;
  private readonly entries: ConnectionEntry[] = [];

  private shouldLog = false;

  constructor({ maxEntries }: { maxEntries: number }) {
    this.maxEntries = maxEntries;
  }

  public push(entry: ConnectionEntry): void {
    this.entries.push(entry);
    if (this.entries.length > this.maxEntries) {
      this.entries.shift();
    }

    if (entry.status === ConnectionStatus.DISCONNECTED) {
      this.numberDisconnects++;
    }

    if (entry.status === ConnectionStatus.CONNECTED) {
      if (this.numberDisconnects > 10) {
        logInfoMsg("Client reconnected several times", {
          safe: { numberDisconnects: this.numberDisconnects },
        });
      }
    }

    if (entry.status === ConnectionStatus.DEGRADED_CONNECTION) {
      logInfoMsg(
        "Degraded connection: client has not heard back from the server which could indicate a dropped connection",
      );
      this.numberOfDegradedConnections++;
    }

    if (this.shouldLog) {
      // eslint-disable-next-line no-console
      console.log(formatConnectionEntry(entry));
    }
  }

  public getAll(): readonly ConnectionEntry[] {
    return this.entries;
  }

  public getAllFormatted(): string {
    return this.getAll()
      .map((entry) => formatConnectionEntry(entry))
      .join("\n");
  }

  public printAllFormatted(): void {
    // eslint-disable-next-line no-console
    console.log(this.getAllFormatted());
  }

  public toggleLogging = (): void => {
    this.shouldLog = !this.shouldLog;
    // eslint-disable-next-line no-console
    console.log("logging enabled for connection log");
  };
}

export const CONNECTION_LOG = new ConnectionLog({ maxEntries: 10 });
