import { Client } from "graphql-ws";

export interface LatencyEvent {
  latency: number;
  datetime: Date;
}

export class ApolloWebSocketDebugger {
  private wsClient: Client | null = null;
  private lastLatency: LatencyEvent | null = null;
  private lastPingTime: number | null = null;

  public setWsClient(wsClient: Client): void {
    this.wsClient = wsClient;
  }

  public terminateWebsocket(): void {
    this.wsClient?.terminate();
  }

  public handleWsPing(): void {
    this.lastPingTime = Date.now();
  }

  public handleWsPong(): void {
    if (this.lastPingTime == null) {
      this.lastLatency = null;
      return;
    }

    this.lastLatency = {
      latency: Date.now() - this.lastPingTime,
      datetime: new Date(),
    };
    this.lastPingTime = null;
  }

  public getLatency(): LatencyEvent | null {
    return this.lastLatency;
  }
}
