import { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const glow = keyframes`
  from {
    opacity: 0.8;
  }

  to {
    opacity: 0.3;
  }
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const shine = keyframes`
  0% {left: -100px}
  20% {left: 100%}
  100% {left: 100%}
`;

export const gradientAnimation = keyframes`
    0%{background-position:200% 50%}
    100%{background-position:0% 50%}
`;
