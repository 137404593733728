import {
  AppTheme,
  ConditionalFormattingCellStyle,
  ConditionalFormattingGradientStyle,
  HexColor,
  ThemeType,
} from "@hex/common";
import type { editor as Editor } from "monaco-editor";
import { Interpolation, ThemeProps } from "styled-components";

import { DARK_THEME } from "../themes/darkTheme";
import { LIGHT_THEME } from "../themes/lightTheme";

import { HexThemeColors } from "./sharedThemeStyles";

export type ThemeInterpolation = Interpolation<ThemeProps<Theme>>;

export const UNIQUE_THEMES = new Set([LIGHT_THEME, DARK_THEME]);

export const THEME_DISPLAY_NAMES: Record<AppTheme, string> = {
  LIGHT: "Light",
  DARK: "Dark",
  SYS_PREF: "System preference",
};

export const ThemeByName: Record<AppTheme, Theme> = {
  DARK: DARK_THEME,
  LIGHT: LIGHT_THEME,
  SYS_PREF: LIGHT_THEME,
};

export const DEFAULT_THEME: AppTheme = AppTheme.SYS_PREF;
interface PillTheme {
  backgroundColor: string;
  color: string;
  css: ThemeInterpolation;
}

export interface PillThemes {
  RED: PillTheme;
  COBALT: PillTheme;
  VIOLET: PillTheme;
  INDIGO: PillTheme;
  FOREST: PillTheme;
  GREEN: PillTheme;
  GRAY: PillTheme;
  WHITE: PillTheme;
  TURQUOISE: PillTheme;
}

export type MarkdownSize = "small" | "default" | "large";

export type CodeColorsType = {
  general: {
    DEFAULT: string;
    INVALID: string;
    SELECTION: string;
    IDENTIFIER: string;
    DELIMITER: string;
    COMMENT: string;
    BOOLEAN: string;
    STRING: string;
    KEYWORD: string;
    NUMBER: string;
    TAG: string;
  };
  appView: {
    BACKGROUND: string;
  };
  html: {
    ATTRIBUTE: string;
    STRING: string;
  };
  css: {
    DEFAULT: string;
  };
  sql: {
    PREDEFINED: string;
  };
  calc: {
    COLUMN: string;
    PARAMETER_REFERENCE: string;
  };
};

interface ButtonTheme {
  fontColor: string;
  backgroundColor: string;
  borderColor?: string;
}

interface ButtonStyle {
  primary: ButtonTheme;
  success: ButtonTheme;
  warning: ButtonTheme;
  danger: ButtonTheme;
  none: ButtonTheme;
}

interface StepperTheme {
  activeStepFontColor: string;
  activeStepBackgroundColor: string;
  inactiveStepFontColor: string;
  inactiveStepBackgroundColor: string;
}

interface CalloutOrToastBackground {
  primary: string;
  success: string;
  warning: string;
  danger: string;
  none: string;
}

interface TourTheme {
  accentColor: string;
  background: string;
  fontColor: string;
  mutedFontColor: string;
  pulsingIndicatorInnerColor: string;
  pulsingIndicatorMiddleColor: string;
  pulsingIndicatorOuterColor: string;
}

export interface BentoTheme {
  jumpBackIn: string;
  popular: string;
  collections: string;
  recentlyPublished: string;
  learn: string;
}

interface TableTheme {
  activeCalcCellBackground: string;
  activeCalcHeaderBackground: string;
  activeCalcBorderColor: string;
  loadingGhostColor: string;
  placeholderCellBackgroundColor: string;
}

export interface MarkdownFontSize {
  PARAGRAPH: string;
  HEADER_FOUR: string;
  HEADER_THREE: string;
  HEADER_TWO: string;
  HEADER_ONE: string;
}

export type ConditionalFormattingCellColors = Record<
  ConditionalFormattingCellStyle,
  {
    backgroundColor: string;
    color: string;
  }
>;

export type ConditionalFormattingGradientColors = Record<
  ConditionalFormattingGradientStyle,
  {
    colors: string[];
  }
>;

type StatusCategoryColor = Record<HexColor, string>;

export interface Theme {
  type: ThemeType;

  activeColor: string;
  adminHeaderFontSize: {
    HEADER_ONE: string;
    HEADER_TWO: string;
  };
  animation: {
    duration: string;
    durationMs: number;
    durationMedium: string;
    durationMediumMs: number;
    durationLarge: string;
    durationLargeMs: number;
    easing: string;
  };
  ansi: {
    BLACK_FG: string;
    RED_FG: string;
    GREEN_FG: string;
    YELLOW_FG: string;
    BLUE_FG: string;
    MAGENTA_FG: string;
    CYAN_FG: string;
    WHITE_FG: string;
    BRIGHT_BLACK_FG: string;
    BRIGHT_RED_FG: string;
    BRIGHT_GREEN_FG: string;
    BRIGHT_YELLOW_FG: string;
    BRIGHT_BLUE_FG: string;
    BRIGHT_MAGENTA_FG: string;
    BRIGHT_CYAN_FG: string;
    BRIGHT_WHITE_FG: string;
  };
  appBuilderRowHover: string;
  magic: {
    backgroundGradient: string;
    backgroundColor: string;
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    diffInsertColor: string;
    diffDeleteColor: string;
    diffFillerColor: string;
    promptBarShadow: string;
    magicModalShadow: string;
  };
  cellMargin: number;
  conditionalFormatting: {
    cell: ConditionalFormattingCellColors;
    gradients: ConditionalFormattingGradientColors;
  };
  dragAndDrop: {
    dragPreview: {
      backgroundNew: string;
      backgroundOther: string;
      borderColor: string;
      border: string;
      color: string;
      text: ThemeInterpolation;
    };
    dragPreviewBlocked: {
      background: string;
      border: string;
      color: string;
      text: ThemeInterpolation;
    };
    selectedElementBorderColor: string;
  };
  graph: {
    dotColor: string;
    lineColor: string;
  };
  resets: {
    a: ThemeInterpolation;
  };
  codeBackground: string;
  colors: HexThemeColors;
  colorSwatchPlaceholder: string;
  disabledState: ThemeInterpolation;
  fileDropZone: {
    backgroundColor: ThemeInterpolation;
    borderColor: ThemeInterpolation;
  };
  userActionColor: string; // For comments and stars
  componentsColor: {
    foreground: string;
    background: string;
  };
  calculationsColor: {
    icon: string;
    background: string;
  };
  backgroundColor: {
    DARK: string;
    DEFAULT: string;
    LOGIC: string;
    MUTED: string;
    OVERLAY: string;
  };
  button: {
    default: ButtonStyle;
    subtleOrMinimal: ButtonStyle;
  };
  borderColor: {
    DEFAULT: string;
    INVERSE: string;
    MUTED: string;
  };
  borderRadius: string;
  boxShadow: {
    ACTION_BAR: string;
    BUTTON: string;
    CARD: string;
    CARD_HOVER: string;
    CARD_FLAT_HOVER: string;
    CARD_SUBTLE: string;
    CARD_SUBTLE_HOVER: string;
    CARD_SMALL: string;
    CELL: string;
    CELL_FOCUS: string;
    CELL_SUCCESS: string;
    CELL_WARNING: string;
    CELL_DANGER: string;
    CELL_HOVER: string;
    COLOR_SWATCH: string;
    COLOR_SWATCH_HOVER: string;
    DIALOG: string;
    EXPLORE_DROP_AREA: string;
    FOCUS_PRIMARY: string;
    FOCUS_WARNING: string;
    FOCUS_ERROR: string;
    INPUT: string;
    INPUT_FOCUS: string;
    NAV_BAR: string;
    POPOVER: string;
    SWITCH: string;
    TOAST: string;
    FULL_PAGE_DIALOG_RED: string;
    FULL_PAGE_DIALOG_BLUE: string;
    FULL_PAGE_DIALOG_PURPLE: string;
  };
  fontFamily: { DEFAULT: string; MONO: string };
  fontSize: {
    EXTRA_SMALL: string;
    SMALL: string;
    DEFAULT: string;
    LARGE: string;
    EXTRA_LARGE: string;
    HEADING_FIVE: string;
    HEADING_FOUR: string;
    HEADING_THREE: string;
    HEADING_TWO: string;
    HEADING_ONE: string;
    PROJECT_TITLE: string;
    DISPLAY_HEADING_ONE: string;
  };
  lineHeight: {
    EXTRA_SMALL: string;
    SMALL: string;
    DEFAULT: string;
    LARGE: string;
    EXTRA_LARGE: string;
    MARKDOWN_HEADING: string;
  };
  fontWeight: {
    NORMAL: number;
    MEDIUM: number;
    SEMI_BOLD: number;
    BOLD: number;
  };
  fontColor: {
    DEFAULT: string;
    MUTED: string;
    PLACEHOLDER: string;
    LINK: string;
  };
  letterSpacing: {
    DISPLAY: string;
    HEADING: string;
    MARKDOWN_HEADING: string;
  };
  hoverColor: string;
  NonTransparentHoverColor: string;
  highlightColor: string;
  iconColor: string;
  iconSize: {
    DEFAULT: number;
    LARGE: number;
  };
  logoColor: string;
  intent: {
    PRIMARY: string;
    SUCCESS: string;
    WARNING: string;
    DANGER: string;
  };
  marketingColors: {
    AMETHYST: string;
    BACKGROUND: string;
    CEMENT: string;
    CITRINE: string;
    JADE: string;
    OPAL: string;
    ROSE_QUARTZ: string;
    VIOLET_TOPAZ: string;
  };
  mediaQuery: {
    MOBILE: number;
    EXTRA_SMALL: number;
    SMALL: number;
    MEDIUM: number;
    LARGE: number;
    EXTRA_LARGE: number;
  };
  menuItem: {
    activeText: string;
    activeBackground: string;
  };
  pill: PillThemes;
  markdownCodeTheme: (
    isInAppView: boolean,
    allowScroll: boolean,
  ) => Record<string, Record<string, string>>;
  monacoPill: PillThemes;
  monacoTheme: {
    name: string;
    options: Editor.IStandaloneThemeData;
  };
  searchHighlightColor: string;
  searchHighlightFocusColor: string;
  statusCategoryColor: StatusCategoryColor;
  stepper: StepperTheme;
  CalloutOrToastBackground: CalloutOrToastBackground;
  tour: TourTheme;
  bento: BentoTheme;
  table: TableTheme;
  bgTextureOpacity: number;
  globalCSSVars: ThemeInterpolation;
  markdownLargeFontSize: MarkdownFontSize;
  markdownDefaultFontSize: MarkdownFontSize;
  markdownSmallFontSize: MarkdownFontSize;
  markdownStyles: (size: MarkdownSize) => ThemeInterpolation;

  storyMaxWidth: number;
  subNavigationBreakpoint: number;
  homeMaxWidth: number;

  useInvertedConnectionIcons: boolean;
  vegaTooltipTheme: "light" | "dark";
  unreadIndicator: {
    backgroundColor: string;
    fontColor: string;
  };
}
