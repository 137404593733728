import { assertNever, uuid } from "@hex/common";
import {
  Provider,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useDebouncedCallback } from "../hooks/useDebouncedCallback";

import { ConnectionStatus } from "./ConnectionStatus";

export type ConnectionListener = (
  newConnectionStatus: ConnectionStatus,
) => void;

/**
 * Manages Apollo websocket connection listeners so that components/hooks can
 * execute code in response to network disconnects/reconnects.
 *
 * This exists since Apollo client doesn't really expose anything like this
 * that can be consumed deep in the react component tree.
 */
export class ConnectionManager {
  private readonly listeners: Record<string, ConnectionListener> = {};
  private currentConnectionStatus: ConnectionStatus | undefined = undefined;

  /**
   * @returns The last known connection status
   */
  public getCurrentStatus = (): ConnectionStatus | undefined => {
    return this.currentConnectionStatus;
  };

  /**
   * Subscribe a callback to apollo websocket connection changes.
   * Be sure to call the unsubcribe function to prevent leaks!
   *
   * @returns unsubscribe function.
   */
  public subscribe = (callback: ConnectionListener): (() => void) => {
    const id = uuid();
    this.listeners[id] = callback;
    return () => {
      delete this.listeners[id];
    };
  };

  /**
   * Notify all listener's that a change in connection status has occured.
   *
   * Its important to leave this as a lambda since it is consumed as
   * a raw function elsewhere.
   */
  public broadcast = (newConnectionStatus: ConnectionStatus): void => {
    if (newConnectionStatus !== this.currentConnectionStatus) {
      this.currentConnectionStatus = newConnectionStatus;
      Object.values(this.listeners).forEach((listener) =>
        listener(newConnectionStatus),
      );
    }
  };
}

const ConnectionManagerContextInternal =
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  createContext<ConnectionManager | undefined>(undefined);
ConnectionManagerContextInternal.displayName = "ConnectionManagerContext";

// cast to non-nullable, so we don't inadvertenly pass undefined and cause crashes
export const ConnectionManagerProvider =
  ConnectionManagerContextInternal.Provider as Provider<ConnectionManager>;

export function useConnectionManager(): ConnectionManager {
  const manager = useContext(ConnectionManagerContextInternal);
  if (!manager) {
    throw new Error("Connection Manager context has not been initialized!");
  }
  return manager;
}

export interface UseOnConnectionChangeOptions {
  callback: ConnectionListener;
}

/**
 * Registers a callback that listens for Apollo websocket connection changes.
 *
 * To prevent constantly attaching and unattaching the listener, should
 * also use `useCallback`.
 */
export function useOnConnectionChange({
  callback,
}: UseOnConnectionChangeOptions): void {
  const manager = useConnectionManager();
  useEffect(() => {
    const unsubscribe = manager.subscribe(callback);
    return () => {
      unsubscribe();
    };
  }, [callback, manager]);
}

export function useCurrentConnectionStatus(): ConnectionStatus | undefined {
  const manager = useConnectionManager();

  const [connectionStatus, setConnectionStatus] = useState(
    manager.getCurrentStatus(),
  );
  // we debounce so that we aren't flickering too frequently
  const setConnectionStatusDebounced = useDebouncedCallback(
    setConnectionStatus,
    2500,
  );

  const onConnectionChange: ConnectionListener = useCallback(
    (newStatus) => {
      switch (newStatus) {
        case ConnectionStatus.CONNECTED:
          setConnectionStatusDebounced(newStatus);
          // if setting the state to connected
          // update the connection status immediately
          setConnectionStatusDebounced.flush();
          break;
        case ConnectionStatus.CONNECTING:
        case ConnectionStatus.DISCONNECTED:
          setConnectionStatusDebounced(newStatus);
          break;
        case ConnectionStatus.DEGRADED_CONNECTION:
          // TODO(FOUND-252): Change the status connection here in the future - like disconnecting so it can be reconnected.
          break;
        default:
          assertNever(newStatus, newStatus);
      }
    },
    [setConnectionStatusDebounced],
  );

  useOnConnectionChange({ callback: onConnectionChange });

  return connectionStatus;
}
