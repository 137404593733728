import { Classes } from "@blueprintjs/core";
import { rgba } from "polished";
import { createGlobalStyle } from "styled-components";

import {
  POPOVER_POINTER_EVENTS_NONE,
  popoverStyles,
} from "../../hex-components/HexPopover";

import { reset } from "./reset";
import { Theme } from "./theme";
import { toastStyles } from "./toasts.js";

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  ${reset}

  html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;

    color: ${({ theme }) => theme.fontColor.DEFAULT};
    font-family: ${({ theme }) => theme.fontFamily.DEFAULT};

    background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};

    overscroll-behavior-x: contain;

    strong {
      font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
    }

    a {
      color: ${({ theme }) => theme.fontColor.LINK};
    }

    ${({ theme }) => theme.globalCSSVars}
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => rgba(theme.iconColor, 0.4)};
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 10px;

    /* Add a bit more border where the track border will be for accurate alignment */
    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    &:vertical {
      border-left: 3px solid rgba(0, 0, 0, 0);
    }

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    &:horizontal {
      border-top: 3px solid rgba(0, 0, 0, 0);
    }

    &:hover {
      background-color: ${({ theme }) => rgba(theme.iconColor, 0.6)};
    }
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    &:horizontal {
      border-top: 1px solid ${({ theme }) => theme.borderColor.MUTED};
    }
  }

  .${Classes.POPOVER} {
    ${popoverStyles}
  }

  /*
    Certain components (Popover) only give us portal classNames to overwrite styles. In the case of pointer-events, for certain popovers,
    these need to be applied at the portal level since portals are rendered outside of the tree (i.e. can't use styled-components),
    and content is usually rendered as a child container
  */
  .${Classes.PORTAL}.${POPOVER_POINTER_EVENTS_NONE} {
    pointer-events: none;
  }

  /* It's hard to use custom components in BP for toasts, so just style ourselves */
  ${toastStyles}

  #root {
    width: 100%;
    height: 100%;
  }
`;
